import React, { Fragment, useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom'; 
import { InterfaceMenuItemWithoutIcon } from '../../GlobalInterface';

export const LayoutsProvidersServices: React.FC = () => {

    const url = `/admin/service/`;
    const location = useLocation().pathname;
    const [tab, Settab] = useState<string>('Properties');
 
    useEffect(() => {
        document.title = `Traning - ${tab}`;
    }, [tab]);

    const menu: InterfaceMenuItemWithoutIcon[] = [
        {
            name: "Fournisseurs",
            path: "vendors",
            sub_navbar: null,
            location_path: url
        },
        {
            name: "Produits",
            path: "products/surprises",
            sub_navbar: "products/",
            location_path: url
        },
    ];

    return (
        <>
            <section id='admin-providers-services-layouts'>
                <ul className="custom-navbar-nav-bottom-light">
                    {
                        menu.map((item, index) => (
                            <Fragment key={`unique-${item.name}`}>
                                <li className="nav-item" key={`unique-${index}`} onClick={() => Settab(item.name)}>
                                    <Link to={item.location_path + item.path} className={`nav-link ${(location.startsWith(item.location_path + item.path) || location.startsWith(item.location_path + item.sub_navbar)) && 'active'}`}>{item.name}</Link>
                                </li>
                            </Fragment>
                        ))
                    }
                </ul>
                <div className="content-childreen">
                    <Outlet />
                </div>
            </section>
        </>
    );
};
