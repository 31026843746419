import React, { Fragment, useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { InterfaceMenuItemWithoutIcon } from '../../GlobalInterface';
import { urlSettings } from '../models/ModelsSettings';

export const LayoutsSettings: React.FC = () => {

    const location = useLocation().pathname;
    const [tab, Settab] = useState<string>('Properties');

    useEffect(() => {
        document.title = `Traning - ${tab}`;
    }, [tab]);

    const menu: InterfaceMenuItemWithoutIcon[] = [
        {
            name: "Geographies",
            path: "geographies/countries",
            sub_navbar: "geographies/",
            location_path: urlSettings
        },
        {
            name: "Evenements",
            path: "events/type-of-surprise",
            sub_navbar: null,
            location_path: urlSettings
        },
        {
            name: "Gestion des acces",
            path: "access-management/privileges",
            sub_navbar: "access-management/",
            location_path: urlSettings
        },
        {
            name: "Type de produits",
            path: "product-types/beneficiary-link-types",
            sub_navbar: "product-types/",
            location_path: urlSettings
        },
        {
            name: "Type de media",
            path: "media-types",
            sub_navbar: null,
            location_path: urlSettings
        },
        {
            name: "Sécurité",
            path: "securities",
            sub_navbar: null,
            location_path: urlSettings
        },
        {
            name: "Paiements",
            path: "payments",
            sub_navbar: null,
            location_path: urlSettings
        },
    ];

    return (
        <>
            <section id='admin-settings-layouts'>
                <ul className="custom-navbar-nav-bottom-light">
                    {
                        menu.map((item, index) => (
                            <Fragment key={`unique-${item.name}`}>
                                <li className="nav-item" key={`unique-${index}`} onClick={() => Settab(item.name)}>
                                    <Link to={item.location_path + item.path} className={`nav-link ${(location.startsWith(item.location_path + item.path) || location.startsWith(item.location_path + item.sub_navbar)) && 'active'}`}>{item.name}</Link>
                                </li>
                            </Fragment>
                        ))
                    }
                </ul>
                <div className="content-childreen">
                    <Outlet />
                </div>
            </section>
        </>
    );
};
