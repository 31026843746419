import React, { useState } from 'react';
import { Link, useLocation, Outlet } from 'react-router-dom';
import {
    PiSquaresFour,
    PiUserSquareLight,
    PiTarget,
    PiPresentationChartThin,
} from 'react-icons/pi';
import { CiUser } from 'react-icons/ci';
import { FaRegMap } from 'react-icons/fa';
import { MdContentCopy } from 'react-icons/md';
import { AiOutlineStar, AiOutlineSetting } from 'react-icons/ai';
import { RiAccountBoxLine } from 'react-icons/ri';
import { IoIosLogOut } from 'react-icons/io';
import logo from '../assets/images/logo/Logo_icon.png';
import userEmpty from '../assets/images/user_empty.png';
import { ToastMessageTopRight } from './ToastMessage';
import { ModelTemplapleSimple } from './CustomModal';
import { KCustomButtonDouble } from './Button';
import { ServiceSessions } from '../services/ServicesSessions';

interface MenuItem {
    name: string;
    path: string;
    sub_navbar: string | null;
    icon: JSX.Element;
    location_path: string;
}

export const SideBarNavBar: React.FC = () => {
    
    const url = `/admin/`;
    const location = useLocation().pathname;
    const [showModalLogout, setShowModalLogout] = useState<boolean>(false);
    const [loadData, setLoadData] = useState<boolean>(false);

    const menu: MenuItem[] = [
        {
            name: "Dashboard",
            path: "dashboard",
            sub_navbar: null,
            icon: <PiSquaresFour className='icon' />,
            location_path: url
        },
        {
            name: "Users Management",
            path: "users",
            sub_navbar: null,
            icon: <PiUserSquareLight className='icon' />,
            location_path: url
        },
        {
            name: "Clients Requests",
            path: "requests",
            sub_navbar: null,
            icon: <CiUser className='icon' />,
            location_path: url
        },
        {
            name: "Providers & Services",
            path: "service/vendors",
            sub_navbar: "service/",
            icon: <FaRegMap className='icon' />,
            location_path: url
        },
        {
            name: "Customers bases",
            path: "customers",
            sub_navbar: null,
            icon: <MdContentCopy
                className='icon' />,
            location_path: url
        },
        {
            name: "Beneficiares bases",
            path: "beneficiares",
            sub_navbar: null,
            icon: <AiOutlineStar className='icon' />,
            location_path: url
        },
        {
            name: "Expenses",
            path: "expenses",
            sub_navbar: null,
            icon: <PiTarget className='icon' />,
            location_path: url
        },
        {
            name: "Orders",
            path: "orders",
            sub_navbar: null,
            icon: <PiPresentationChartThin className='icon' />,
            location_path: url
        },
        {
            name: "Settings",
            path: "settings/geographies/countries",
            sub_navbar: "settings/",
            icon: <AiOutlineSetting className='icon' />,
            location_path: url
        },
        {
            name: "My Account",
            path: "account",
            sub_navbar: null,
            icon: <RiAccountBoxLine className='icon' />,
            location_path: url
        },
    ];

    const logout_ = async () => {
        setLoadData(true);
        // await ServicesAuthentication.logout();
        ServiceSessions.logout();
        setLoadData(false);
    };

    return (
        <>
            <section id='field36-content-admin'>
                <div id='sidebar' className='sidebar'>
                    <nav className='sidebar-content'>
                        <Link to='/admin/homes' className='logo'>
                            <span className='logo'>
                                <img src={logo} width={60} height={50} alt='ppp' />
                            </span>
                        </Link>
                        <div className="sd-body">
                            <ul className='navbar-nav'>
                                {menu.map((item, index) => (
                                    <li className='item-li' key={`unique-${index}`}>
                                        <Link to={item.path} className={`${(location.startsWith(item.location_path + item.path) || location.startsWith(item.location_path + item.sub_navbar)) && "active"} item-link`} >
                                            {item.icon} {item.name}
                                        </Link>
                                    </li>
                                ))}
                                <li className='item-li'>
                                    <Link to='#' onClick={() => setShowModalLogout(true)} className='item-link'>
                                        <IoIosLogOut className='icon' /> Logout
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <ToastMessageTopRight />
                <div id='navbar'>
                    <nav className="navbar">
                        <div className="content-navbar">
                            <span>Country: Cote d'Ivoire</span>
                            <div className="left">
                                <span>Welcome, Gilles</span>
                                <img src={userEmpty} width={40} height={40} alt='user_photo' />
                            </div>
                        </div>
                    </nav>
                    <div className='full-content'>
                        <div className='card-custom'>
                            <Outlet />
                        </div>
                    </div>
                </div>
            </section>
            <ModelTemplapleSimple
                show={showModalLogout}
                hide={() => setShowModalLogout(false)}
                children={
                    <div className='grid-custom-one-column grid-gap-custom-normal'>
                        <p className='mb-4'>Souhaitez-vous vous déconnecter ?</p>
                        <KCustomButtonDouble
                            loading={loadData}
                            textel='Non'
                            texter='Oui'
                            clickl={() => setShowModalLogout(false)}
                            clickr={logout_}
                            typel='button'
                            typer='button'
                        />
                    </div>
                }
                className='content'
                modalClassName='modal-logout'
            />
        </>
    );
};
