import { Route, Routes } from "react-router-dom";
import { Expenses } from "../views/Expenses";


export const ControllersExpenses: React.FC = () => {
    return (
        <>
            <Routes>
                <Route path='' element={<Expenses />} />
            </Routes>
        </>
    );
};