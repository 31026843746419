import { Route, Routes } from "react-router-dom";
import { Dashboards } from "../views/Dashboards";

export const ControllersDashboards : React.FC = () => {
    return (
        <>
            <Routes>
                <Route index element={<Dashboards />} />
                <Route path='login' element={<Dashboards />} />
            </Routes>
        </>
    );
};