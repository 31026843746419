import React from 'react';
import { Link } from 'react-router-dom';
import { KCustomButtonSimpleOuline } from '../../../../../components/Button';

export const AuditTrail: React.FC = () => {
    return (
        <>
            <section id='admin-settings-security' className='content-or-subcontent-settings'>
                <div className='grid-custom-one-column'>
                    <div className='custom-btn-header custom-end'>
                        <Link to='#' className='link-btn-custom'>
                            <KCustomButtonSimpleOuline loading={false} type={`button`} texte={`Add`} />
                        </Link>
                    </div>
                </div>

                <div className='responsible-table-custom'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Action type</th>
                                <th scope="col">Usename</th>
                                <th scope="col">Login</th>
                                <th scope="col">IP</th>
                                <th scope="col">Date and time</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* <tr>
                                <td colSpan="6" className={``}>
                                    {loadData && (
                                        <SpinnerData />
                                    )}
                                    {
                                        !loadData && client.length === 0 && (
                                            <>Aucune information disponible</>
                                        )
                                    }
                                </td>
                            </tr> */}
                            {/* {
                                !loadData && client.map((item, index) =>
                                    <>
                                        <tr>
                                            <td>{item.client_name && item.client_name}</td>
                                            <td>{item.client_industry && item.client_industry.industry_name}</td>
                                            <td>{item.country_id && item.country_id.country_name}</td>
                                            <td>{item.client_status === "1" ? "Actif" : "Desactiver"}</td>
                                            <td>{item.client_logo && <img src={`${baseURLImage}${item.client_logo}`} width={30} height={30} alt="logo country" />}</td>
                                            <td className="td-action">
                                                <Link to={`/admin/clients/edit/${item.id_client}`} className='td-link edit me-2'>
                                                    <MdEdit className='icon' />
                                                </Link>
                                                <Link to='' className='td-link delete ms-2' onClick={() => deleteData(item.id_client, item.client_name)}>
                                                    <MdDelete className='icon' />
                                                 </Link>
                                            </td>
                                        </tr> 
                                    </>
                                )
                            } */}
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
};
