import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ControllersAuths } from './apps/auths/controllers/ControllersAuths';
import { SideBarNavBar } from './components/SideBarNavBar';
import { ControllersDashboards } from './apps/admins/dashboards/controllers/ControllersDashboards';
import { ControllersSettings } from './apps/admins/settings/controllers/ControllersSettings';
import { ControllersProvidersServices } from './apps/admins/providers-services/controllers/ControllersProvidersServices';
import { ControllersUsersManagements } from './apps/admins/users-managements/controllers/ControllersUsersManagement';
import { ControllersOrders } from './apps/admins/orders/controllers/ControllersOrders';
import { ControllersMyAccount } from './apps/admins/my-account/controllers/ControllersMyAccount';
import { ControllersExpenses } from './apps/admins/expenses/controllers/ControllersExpenses';
import { ControllersCustomesBase } from './apps/admins/customers-base/controllers/ControllersCustomesBase';
import { ControllersClientsRequests } from './apps/admins/clients-requests/controllers/ControllersClientsRequests';
import { ControllersBeneficiaresBases } from './apps/admins/beneficiaires-bases/controllers/ControllersBeneficiaresBases';
import { AuthGaurd } from './helpers/AuthGaurd';

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes> 
                <Route path='/*' element={<ControllersAuths />} />
                <Route path='admin/*' element={
                    <AuthGaurd>
                        <AdminsRoutes />
                    </AuthGaurd>
                } />
            </Routes>
        </BrowserRouter>
    );
}

export default App;

const AdminsRoutes: React.FC = () => {

    return (
        <>
            <Routes>
                <Route element={<SideBarNavBar />} >
                    <Route path='dashboard/*' element={<ControllersDashboards />} />
                    <Route path='users/*' element={<ControllersUsersManagements />} />
                    <Route path='requests/*' element={<ControllersClientsRequests />} />
                    <Route path='service/*' element={<ControllersProvidersServices />} />
                    <Route path='customers/*' element={<ControllersCustomesBase />} />
                    <Route path='beneficiares/*' element={<ControllersBeneficiaresBases />} />
                    <Route path='expenses/*' element={<ControllersExpenses />} />
                    <Route path='orders/*' element={<ControllersOrders />} />
                    <Route path='settings/*' element={<ControllersSettings />} />
                    <Route path='account/*' element={<ControllersMyAccount />} />
                </Route>
            </Routes>
        </>
    );
};