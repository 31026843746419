import { Route, Routes } from "react-router-dom";
import { LayoutsSettings } from "../views/LayoutsSettings";
import { Roles } from "../views/access-management/roles/Roles";
import { Privileges } from "../views/access-management/privilege/Privilege";
import { AuditTrail } from "../views/security/AuditTrail";
import { TypeOfInvestments } from "../views/product-type/type-of-investment/TypeOfInvestment";
import { BeneficiaryLinkTypes } from "../views/product-type/beneficiary-link-type/BeneficiaryLinkType";
import { MeansOfPaiements } from "../views/payments/MeansOfPayment";
import { MediaTypes } from "../views/media-type/MediaTypes";
import { Localities } from "../views/geography/localities/Localities";
import { Countries } from "../views/geography/countries/Countries";
import { TypeOfSurprise } from "../views/events/type-of-surprise/TypeOfSurprise";
import { LayoutsGeography } from "../views/geography/LayoutsGeography";
import { LayoutsAccesManagement } from "../views/access-management/LayoutsAccesManagement";
import { LayoutsProductsTypes } from "../views/product-type/LayoutsProductsTypes";
import { CreateCountries } from "../views/geography/countries/Create";
import { UpdateCountries } from "../views/geography/countries/Update";
import { CreateLocalities } from "../views/geography/localities/Create";
import { UpdateLocalities } from "../views/geography/localities/Update";

export const ControllersSettings: React.FC = () => {
    return (
        <>
            <Routes>
                <Route path='/*' element={<LayoutsSettings />} >

                    <Route path='access-management/*'  element={<LayoutsAccesManagement />} >
                        <Route path='privileges/*' >
                            <Route path='' element={<Privileges />} />
                            <Route path='create' element={<Privileges />} />
                            <Route path='update/:privileges' element={<Privileges />} />
                        </Route>
                        <Route path='roles/*' >
                            <Route path='' element={<Roles />} />
                            <Route path='create' element={<Roles />} />
                            <Route path='update/:roles' element={<Roles />} />
                        </Route>
                    </Route>

                    <Route path='events/*' >
                        <Route path='type-of-surprise' element={<TypeOfSurprise />} />
                    </Route>

                    <Route path='geographies/*' element={<LayoutsGeography />} >
                        <Route path='countries/*' >
                            <Route path='' element={<Countries />} />
                            <Route path='create' element={<CreateCountries />} />
                            <Route path='update/:id_countries' element={<UpdateCountries />} />
                        </Route>
                        <Route path='localities/*' >
                            <Route path='' element={<Localities />} />
                            <Route path='create' element={<CreateLocalities />} />
                            <Route path='update/:id_localities' element={<UpdateLocalities />} />
                        </Route>
                    </Route>

                    <Route path='media-types/*'  >
                        <Route path='' element={<MediaTypes />} />
                    </Route>

                    <Route path='payments/*' >
                        <Route path='' element={<MeansOfPaiements />} />
                    </Route>

                    <Route path='product-types/*'   element={<LayoutsProductsTypes />}>
                        <Route path='beneficiary-link-types/*' >
                            <Route path='' element={<BeneficiaryLinkTypes />} />
                        </Route>
                        <Route path='type-of-investments/*' >
                            <Route path='' element={<TypeOfInvestments />} />
                        </Route>
                    </Route>

                    <Route path='securities/*'  >
                        <Route path='' element={<AuditTrail />} />
                    </Route>

                </Route>
            </Routes>
        </>
    );
};