import React, { Fragment, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { InterfaceMenuItemWithoutIcon } from '../../../GlobalInterface';
import { Link } from 'react-router-dom';
import { urlProductTypes } from '../../models/ModelsSettings';

export const LayoutsProductsTypes = () => {
 
    const location = useLocation().pathname;
    const [tab, Settab] = useState<string>('Properties');

    useEffect(() => {
        document.title = `Traning - ${tab}`;
    }, [tab]);

    const menu: InterfaceMenuItemWithoutIcon[] = [
        {
            name: "Beneficiare link type",
            path: "beneficiary-link-types",
            sub_navbar: null,
            location_path: urlProductTypes
        },
        {
            name: "Type d'investissement",
            path: "type-of-investments",
            sub_navbar: null,
            location_path: urlProductTypes
        },
    ];

    return (
        <>
            <section id='admin-settings-productsTypes' className='content-or-subcontent-settings'>
                <ul className="custom-navbar-nav-full">
                    {
                        menu.map((item, index) => (
                            <Fragment key={`unique-${item.name}`}>
                                <li className="nav-item" key={`unique-${index}`} onClick={() => Settab(item.name)}>
                                    <Link to={item.location_path + item.path} className={`nav-link ${(location.startsWith(item.location_path + item.path) || location.startsWith(item.location_path + item.sub_navbar)) && 'active'}`}>{item.name}</Link>
                                </li>
                            </Fragment>
                        ))
                    }
                </ul>
                <div className="content-childreen">
                    <Outlet />
                </div>
            </section>
        </>
    );
};

