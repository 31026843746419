

export const urlSettings: string = "/admin/settings/";

// ------------------------------------------------------------------------
// --------------------- Interface Geographie and URL ---------------------
// ------------------------------------------------------------------------

export const urlGeographie: string = `${urlSettings}geographies/`;
export const urlCountries: string = `${urlGeographie}countries`;
export const urlLocalities: string = `${urlGeographie}localities`;
 
// ------------------------------- COUNTRIES -------------------------------
export interface InterfaceCountriesCreate {
    country_name: string;
    country_prefixe: string;
    username: string | null;
    token: string | null;
    country_flag_base64: string | string;
}

export interface InterfaceCountriesUpdate {
    country_name: string;
    country_prefixe: string;
    id_country: number;
    country_phone_length: number | null;
    country_code: string;
    username: string | null;
    token: string | null;
    country_flag_base64: string | string;
}

export interface InterfaceCountriesGet {
    id_country: number;
    country_name: string;
    country_code: string;
    country_phone_length: number;
    country_prefixe:  string;
    country_flag_url: string;
}
 
// ------------------------------- LOCALITIES -------------------------------
export interface InterfaceLocalitiesCreate {
    locality_name: string;
    locality_status: boolean | null | string;
    id_country: number | null; 
    username: string | null;
    token: string | null; 
}

export interface InterfaceLocalitiesUpdate {
    id_locality: number;
    locality_name: string;
    locality_status: boolean;
    id_country: number; 
    username: string | null;
    token: string | null; 
}

export interface InterfaceLocalitiesGet {
    id_locality: number;
    locality_name: string;
    locality_status: boolean;
    country_name: string; 
}
 
// export interface InterfacecountriesDelete {
//     id_country: number;
//     username: string | null;
//     token: string | null;
// }

// ------------------------------------------------------------------------
// ---------------- Interface Acces management and URL --------------------
// ------------------------------------------------------------------------

export const urlAccessManagement: string = `${urlSettings}access-management/`;
export const urlPrivilege: string = `${urlAccessManagement}privileges`;
export const urlRoles: string = `${urlAccessManagement}roles`;

// ------------------------------- PRILILEGES -------------------------------
export interface InterfacePrivilegeCreate {
    country_name: string;
    country_prefixe: string;
    username: string | null;
    token: string | null;
    country_flag_base64: string | string;
}

export interface InterfacePrivilegeUpdate {
    country_name: string;
    country_prefixe: string;
    id_country: number;
    country_phone_length: number | null;
    country_code: string;
    username: string | null;
    token: string | null;
    country_flag_base64: string | string;
}

export interface InterfacePrivilegeGet {
    id_country: number;
    country_name: string;
    country_code: string;
    country_phone_length: number;
    country_prefixe:  string;
    country_flag_url: string;
}

// ------------------------------- ROLES -------------------------------
export interface InterfaceRolesCreate {
    country_name: string;
    country_prefixe: string;
    username: string | null;
    token: string | null;
    country_flag_base64: string | string;
}

export interface InterfaceRolesUpdate {
    country_name: string;
    country_prefixe: string;
    id_country: number;
    country_phone_length: number | null;
    country_code: string;
    username: string | null;
    token: string | null;
    country_flag_base64: string | string;
}

export interface InterfaceRolesGet {
    id_country: number;
    country_name: string;
    country_code: string;
    country_phone_length: number;
    country_prefixe:  string;
    country_flag_url: string;
}


// ------------------------------------------------------------------------
// ------------------- Interface Product types and URL --------------------
// ------------------------------------------------------------------------

export const urlProductTypes: string = `${urlSettings}product-types/`;
export const urlBeneficiaryLinkTypes: string = `${urlProductTypes}beneficiary-link-types`;
export const urlTypeOfInvestment: string = `${urlProductTypes}type-of-investments`;

 
// ------------------------- BENEFICIAIRE LINK TYPE ------------------------
export interface InterfaceBeneficiaryLinkTypesCreate {
    country_name: string;
    country_prefixe: string;
    username: string | null;
    token: string | null;
    country_flag_base64: string | string;
}

export interface InterfaceeneficiaryLinkTypesUpdate {
    country_name: string;
    country_prefixe: string;
    id_country: number;
    country_phone_length: number | null;
    country_code: string;
    username: string | null;
    token: string | null;
    country_flag_base64: string | string;
}

export interface InterfaceeneficiaryLinkTypesGet {
    id_country: number;
    country_name: string;
    country_code: string;
    country_phone_length: number;
    country_prefixe:  string;
    country_flag_url: string;
}

// ------------------------ TYPE D'INVESTISSEMENT --------------------------
export interface InterfaceTypeOfInvestmentsCreate {
    country_name: string;
    country_prefixe: string;
    username: string | null;
    token: string | null;
    country_flag_base64: string | string;
}

export interface InterfaceTypeOfInvestmentsUpdate {
    country_name: string;
    country_prefixe: string;
    id_country: number;
    country_phone_length: number | null;
    country_code: string;
    username: string | null;
    token: string | null;
    country_flag_base64: string | string;
}

export interface InterfaceTypeOfInvestmentsGet {
    id_country: number;
    country_name: string;
    country_code: string;
    country_phone_length: number;
    country_prefixe:  string;
    country_flag_url: string;
}



// ------------------------------- SURPRISE -------------------------------
export interface InterfaceSurpriseCreate {
    country_name: string;
    country_prefixe: string;
    username: string | null;
    token: string | null;
    country_flag_base64: string | string;
}

export interface InterfaceSurpriseUpdate {
    country_name: string;
    country_prefixe: string;
    id_country: number;
    country_phone_length: number | null;
    country_code: string;
    username: string | null;
    token: string | null;
    country_flag_base64: string | string;
}

export interface InterfaceSurpriseGet {
    id_country: number;
    country_name: string;
    country_code: string;
    country_phone_length: number;
    country_prefixe:  string;
    country_flag_url: string;
}



// ------------------------------ PAIEMENT ------------------------------
export interface InterfacePaiementsCreate {
    country_name: string;
    country_prefixe: string;
    username: string | null;
    token: string | null;
    country_flag_base64: string | string;
}

export interface InterfacePaiementsUpdate {
    country_name: string;
    country_prefixe: string;
    id_country: number;
    country_phone_length: number | null;
    country_code: string;
    username: string | null;
    token: string | null;
    country_flag_base64: string | string;
}

export interface InterfacePaiementsGet {
    id_country: number;
    country_name: string;
    country_code: string;
    country_phone_length: number;
    country_prefixe:  string;
    country_flag_url: string;
}



// --------------------------- MEDIA TYPE ----------------------------
export interface InterfacePaiementsGet {
    id_country: number;
    country_name: string;
    country_code: string;
    country_phone_length: number;
    country_prefixe:  string;
    country_flag_url: string;
}



// ----------------------- SECURITIES | AUDIT -------------------------
export interface InterfaceSecuritiesAuditGet {
    id_country: number;
    country_name: string;
    country_code: string;
    country_phone_length: number;
    country_prefixe:  string;
    country_flag_url: string;
}