import React from 'react';



interface KCustomButtonDoubleProps {
    loading: boolean;
    typel: "button" | "submit" | "reset";
    typer: "button" | "submit" | "reset";
    clickl: () => void;
    clickr: () => void;
    textel: string;
    texter: string;
}
export const KCustomButtonDouble: React.FC<KCustomButtonDoubleProps> = ({ loading, typel, typer, clickl, clickr, textel, texter }) => {
    return (
        <>
            <div className='grid-custom-two-column grid-gap-custom-normal'>
                <button type={typel} onClick={clickl} className={`btn-custom-canceled`} disabled={loading}>
                     {textel}
                </button>
                <button type={typer} onClick={clickr} className={loading ? `btn-custom-desable` : `btn-custom-secondary`} disabled={loading}>
                    {loading && <span className="spinner-border spinner-border-sm"></span>}
                    {texter}
                </button>
            </div>
        </>
    );
};
 
interface KCustomButtonSimpleProps {
    loading: boolean;
    type: "button" | "submit" | "reset";
    texte: string;
}
export const KCustomButtonSimple: React.FC<KCustomButtonSimpleProps> = ({ loading, type, texte }) => {
    return (
        <>
            <button type={type} className={loading ? `btn-custom-desable` : `btn-custom-secondary`} disabled={loading}>
                {loading && <span className="spinner-border spinner-border-sm"></span>}
                {texte}
            </button>
        </>
    );
};
export const KCustomButtonSimpleOuline: React.FC<KCustomButtonSimpleProps> = ({ loading, type, texte }) => {
    return (
        <>
            <button type={type} className={loading ? `btn-custom-desable` : `btn-custom-primary`} disabled={loading}>
                {loading && <span className="spinner-border spinner-border-sm"></span>}
                {texte}
            </button>
        </>
    );
};