import { Route, Routes } from "react-router-dom"; 
import { ClientsRequests } from "../views/ClientsRequests";

export const ControllersClientsRequests : React.FC = () => {
    return (
        <>
            <Routes> 
                <Route path='' element={<ClientsRequests />} />
            </Routes>
        </>
    );
};