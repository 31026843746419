import { Route, Routes } from "react-router-dom"; 
import { CustomersBase } from "../views/CustomersBase";

export const ControllersCustomesBase : React.FC = () => {
    return (
        <>
            <Routes> 
                <Route path='' element={<CustomersBase />} />
            </Routes>
        </>
    );
};