import { Route, Routes } from "react-router-dom"; 
import { BeneficiaresBases } from "../views/BeneficiaresBases";

export const ControllersBeneficiaresBases : React.FC = () => {
    return (
        <>
            <Routes> 
                <Route path='' element={<BeneficiaresBases />} />
            </Routes>
        </>
    );
};