import React from 'react';
import { KCustomButtonSimpleOuline } from '../../../../../../components/Button';
import { Link } from 'react-router-dom';



export const TypeOfSurprise: React.FC = () => {
    return (
        <>
            <section id='admin-settings-events-typeOfSurprise' className='content-or-subcontent-settings'>
                <div className='grid-custom-one-column'>
                    <div className='custom-btn-header custom-end'>
                        <Link to='#' className='link-btn-custom'>
                            <KCustomButtonSimpleOuline loading={false} type={`button`} texte={`Add`} />
                        </Link>
                    </div>
                </div>

                <div className='responsible-table-custom'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Surprise type</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* <tr>
                        <td colSpan="6" className={``}>
                            {loadData && (
                                <SpinnerData />
                            )}
                            {
                                !loadData && client.length === 0 && (
                                    <>Aucune information disponible</>
                                )
                            }
                        </td>
                    </tr> */}
                            {/* {
                        !loadData && client.map((item, index) =>
                            <>
                                <tr>
                                    <td>{item.client_name && item.client_name}</td>
                                    <td>{item.client_industry && item.client_industry.industry_name}</td>
                                    <td>{item.country_id && item.country_id.country_name}</td>
                                    <td>{item.client_status === "1" ? "Actif" : "Desactiver"}</td>
                                    <td>{item.client_logo && <img src={`${baseURLImage}${item.client_logo}`} width={30} height={30} alt="logo country" />}</td>
                                    <td className="td-action">
                                        <Link to={`/admin/clients/edit/${item.id_client}`} className='td-link edit me-2'>
                                            <MdEdit className='icon' />
                                        </Link>
                                        <Link to='' className='td-link delete ms-2' onClick={() => deleteData(item.id_client, item.client_name)}>
                                            <MdDelete className='icon' />
                                         </Link>
                                    </td>
                                </tr> 
                            </>
                        )
                    } */}
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
};
