import { Route, Routes } from "react-router-dom";
import { Login } from "../views/Login";

export const ControllersAuths : React.FC = () => {
    return (
        <>
            <Routes>
                <Route path="" element={<Login />} />
                <Route path='login' element={<Login />} />
            </Routes>
        </>
    );
};