import { Route, Routes } from "react-router-dom";
import { MyAccount } from "../views/MyAccount";


export const ControllersMyAccount: React.FC = () => {
    return (
        <>
            <Routes>
                <Route path='' element={<MyAccount />} />
            </Routes>
        </>
    );
};