import { AxiosResponse } from 'axios';
import { BASE_URL, headerRequest } from '../BASE_URL';
import { auhtenticatedResquest } from '../ServicesSessions';
import { InterfaceUserStaffChangePassword, InterfaceUserStaffCreate, InterfaceUserStaffLogin, InterfaceUserStaffUpdate } from '../../apps/auths/models/ModelsUsers';


export class ServicesUserStaff {

    static login = async (data: InterfaceUserStaffLogin): Promise<AxiosResponse<any>> => {
        try {
            const response: AxiosResponse<any> = await BASE_URL.post(
                'login-user/', JSON.stringify(data), {
                headers: headerRequest,
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static changePassword = async (data: InterfaceUserStaffChangePassword): Promise<AxiosResponse<any>> => {
        try {
            const response: AxiosResponse<any> = await BASE_URL.post(
                'update-user-password/', JSON.stringify(data), {
                headers: headerRequest,
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static read = async (): Promise<AxiosResponse<any>> => {
        const data = {
            token: auhtenticatedResquest.token,
            username: auhtenticatedResquest.username
        };
        try {
            const response: AxiosResponse<any> = await BASE_URL.post(
                'read-user/', JSON.stringify(data), {
                headers: headerRequest,
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static create = async (data: InterfaceUserStaffCreate): Promise<AxiosResponse<any>> => {
        try {
            const response: AxiosResponse<any> = await BASE_URL.post(
                'create-user/', JSON.stringify(data), {
                headers: headerRequest,
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static update = async (id: number | string, data: InterfaceUserStaffUpdate): Promise<AxiosResponse<any>> => {
        try {
            const response: AxiosResponse<any> = await BASE_URL.put(
                `update-user/${id}/`,
                JSON.stringify(data),
                {
                    headers: headerRequest,
                }
            );
            return response;
        } catch (error) {
            throw error;
        }
    }

    static delete = async (id: number | string): Promise<AxiosResponse<any>> => {
        try {
            const response: AxiosResponse<any> = await BASE_URL.delete(
                `delete-user/`,
                {
                    headers: headerRequest,
                    data: JSON.stringify({
                        token: auhtenticatedResquest.token,
                        username: auhtenticatedResquest.username,
                        // id_country: Number(id)
                    }),
                }
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}
