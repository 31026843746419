import { Route, Routes } from "react-router-dom";
import { LayoutsProvidersServices } from "../views/LayoutsProvidersServices";
import { Vendors } from "../views/vendors/Vendors";
import { InvestmentsProvidersServices } from "../views/products/investments/Investments";
import { SurprisesProvidersServices } from "../views/products/surprises/Surprises";
import { LayoutspsProductsProvidersServices } from "../views/products/LayoutspsProducts";


export const ControllersProvidersServices: React.FC = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<LayoutsProvidersServices />} >
                    <Route path='vendors/*' >
                        <Route path='' element={<Vendors />} />
                    </Route>
                    <Route path='products/*' element={<LayoutspsProductsProvidersServices />} >
                        <Route path='surprises/*' >
                            <Route path='' element={<SurprisesProvidersServices />} />
                        </Route>
                        <Route path='investments/*' >
                            <Route path='' element={<InvestmentsProvidersServices />} />
                        </Route>
                    </Route>
                </Route>
            </Routes>
        </>
    );
};