import React from 'react';  
import { RotatingLines } from 'react-loader-spinner';

export const SpinnerData: React.FC = () => {
    return (
        <>
            <RotatingLines
                strokeColor="#065F89"
                strokeWidth="1.5"
                animationDuration="0.70"
                width="45"
                visible={true}
            />
        </>
    );
};

export const SpinnerDataSmall: React.FC = () => {
    return (
        <>
            <RotatingLines
                strokeColor="#065F89"
                strokeWidth="1.5"
                animationDuration="0.70"
                width="20"
                visible={true}
            />
        </>
    );
};
