import { AxiosResponse } from "axios";
import { BASE_URL, headerRequest } from "../BASE_URL";
import { auhtenticatedResquest } from "../ServicesSessions";
import { InterfaceLocalitiesCreate, InterfaceLocalitiesUpdate } from "../../apps/admins/settings/models/ModelsSettings";

export class ServicesLocalities {

    static url: string = 'localities';

    static read = async (): Promise<AxiosResponse<any>> => {
        const data = {
            token: auhtenticatedResquest.token,
            username: auhtenticatedResquest.username
        };
        try {
            const response: AxiosResponse<any> = await BASE_URL.post(
                `${this.url}-read/`, JSON.stringify(data), {
                headers: headerRequest,
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static create = async (data: InterfaceLocalitiesCreate): Promise<AxiosResponse<any>> => {
        try {
            const response: AxiosResponse<any> = await BASE_URL.post(
                `${this.url}/`, JSON.stringify(data), {
                headers: headerRequest,
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static update = async (id: number | string, data: InterfaceLocalitiesUpdate): Promise<AxiosResponse<any>> => {
        try {
            const response: AxiosResponse<any> = await BASE_URL.put(
                `${this.url}/${id}/`,
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Accept': 'application/json',
                    }
                }
            );
            return response;
        } catch (error) {
            throw error;
        }
    }

    static delete = async (id: number | string): Promise<AxiosResponse<any>> => {
        try {
            const response: AxiosResponse<any> = await BASE_URL.delete(
                `${this.url}/`,
                {
                    headers: headerRequest,
                    data: JSON.stringify({
                        token: auhtenticatedResquest.token,
                        username: auhtenticatedResquest.username,
                        id_locality: Number(id)
                    }),
                }
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}
