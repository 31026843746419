import { AxiosResponse } from 'axios';
import { BASE_URL, headerRequest, headerRequestAuth } from '../BASE_URL';
import { auhtenticatedResquest } from '../ServicesSessions';
import { InterfaceCountriesCreate, InterfaceCountriesUpdate } from '../../apps/admins/settings/models/ModelsSettings';



export class ServicesCountries {

    static url: string = 'countries';

    static read = async (): Promise<AxiosResponse<any>> => { 
        try {
            const response: AxiosResponse<any> = await BASE_URL.get(
                `${this.url}-read`, {
                headers: headerRequestAuth,
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static create = async (data: InterfaceCountriesCreate): Promise<AxiosResponse<any>> => {
        try {
            const response: AxiosResponse<any> = await BASE_URL.post(
                `${this.url}/`, JSON.stringify(data), {
                headers: headerRequest,
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static update = async (id: number | string, data: InterfaceCountriesUpdate): Promise<AxiosResponse<any>> => {
        try {
            const response: AxiosResponse<any> = await BASE_URL.put(
                `${this.url}/${id}/`,
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Accept': 'application/json',
                    }
                }
            );
            return response;
        } catch (error) {
            throw error;
        }
    }

    static delete = async (id: number | string): Promise<AxiosResponse<any>> => {
        try {
            const response: AxiosResponse<any> = await BASE_URL.delete(
                `${this.url}/`,
                {
                    headers: headerRequest,
                    data: JSON.stringify({
                        token: auhtenticatedResquest.token,
                        username: auhtenticatedResquest.username,
                        id_country: Number(id)
                    }),
                }
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}
