import React, { ReactNode } from 'react';
import { Modal } from "react-bootstrap";
import { IoCloseCircleSharp } from 'react-icons/io5'; 

interface ModelTemplapleSimpleProps {
    show: boolean;
    hide: () => void;
    className: string;
    children: ReactNode;
    modalClassName?: string;
}
export const ModelTemplapleSimple: React.FC<ModelTemplapleSimpleProps> = ({ show, hide, className, children, modalClassName = '' }) => {
    return (
        <Modal
            show={show} 
            aria-labelledby="contained-modal-title-center"
            centered
            className={`modal-form-custom-all ${modalClassName}`}
            backdrop="static"
        >
            <div className='card-custom'>
                <IoCloseCircleSharp role='button' className='close-icon' onClick={hide} />
                <div className={`content content-${className}`}>
                    {children}
                </div>
            </div>
        </Modal>
    );
}
