import React, { Fragment, useEffect, useState } from 'react';
import { KCustomButtonDouble, KCustomButtonSimpleOuline } from '../../../../../../components/Button';
import { Link } from 'react-router-dom';
import { InterfaceCountriesGet, urlCountries } from '../../../models/ModelsSettings';
import { ToastMessageTopRight } from '../../../../../../components/ToastMessage';
import { InterfaceDeleteTableRow } from '../../../../GlobalInterface';
import { ServicesCountries } from '../../../../../../services/api/ServicesCountries';
import { SpinnerData } from '../../../../../../components/SpinnerData';
import { ModelTemplapleSimple } from '../../../../../../components/CustomModal';
import { MdDelete, MdEdit } from 'react-icons/md';
import { urlMedias } from '../../../../../../services/BASE_URL';



export const Countries: React.FC = () => {

    const [countries, setCountries] = useState<InterfaceCountriesGet[]>([]);
    const [loadData, SetLoadData] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
    const [showModalDeleteData, setShowModalDeleteData] = useState<InterfaceDeleteTableRow>({
        name: "",
        id: ""
    });

    useEffect(() => {
        document.title = "Countries";
    });

    useEffect(() => {
        getCountries();
    }, []);

    // Get Countries
    const getCountries = async () => {
        await ServicesCountries.read().then((res) => {
            setCountries(res.data.data);
            SetLoadData(false);
        }).catch((err) => {
            return err
        });
    };

    // Delete Countries
    const deleteCountries = async (id: number) => {
        setLoading(true);
        await ServicesCountries.delete(id).then((res: any) => {
            setShowModalDelete(false);
            getCountries();
            // success message
        }).catch((err) => {
            // error message
        });
        setLoading(false);
    };

    const deleteData = (id: number | string, kname: string) => {
        setShowModalDeleteData({
            name: kname,
            id: id,
        });
        setShowModalDelete(true);
    }

    return (
        <>
            <section id='admin-settings-geography-countries' className='content-or-subcontent-settings'>
                <div className='grid-custom-one-column'>
                    <div className='custom-btn-header custom-end'>
                        <Link to={`${urlCountries}/create`} className='link-btn-custom'>
                            <KCustomButtonSimpleOuline loading={false} type={`button`} texte={`Add`} />
                        </Link>
                    </div>
                </div>

                <div className='responsible-table-custom'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Country</th>
                                <th scope="col">Code</th>
                                <th scope="col">Flag</th>
                                <th scope="col">Prefix</th>
                                <th scope="col">Phone lenght</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={`${countries && countries.length !== 0 && 'd-none'}`}>
                                <td colSpan={6} className={``}>
                                    {loadData && (
                                        <SpinnerData />
                                    )}
                                    {
                                        !loadData && countries.length === 0 && (
                                            <>No data available</>
                                        )
                                    }
                                </td>
                            </tr>
                            {
                                !loadData && countries.map((item, index) =>
                                    <Fragment key={index}>
                                        <tr>
                                            <td>{item.country_name && item.country_name}</td>
                                            <td>{item.country_code && item.country_code}</td>
                                            <td>
                                                {
                                                    item.country_flag_url && (
                                                        <>
                                                            <img className="img-td" src={`${urlMedias}${item.country_flag_url && item.country_flag_url}`} alt='logo countries' />
                                                        </>
                                                    )
                                                }
                                            </td>
                                            <td>{item.country_prefixe && item.country_prefixe}</td>
                                            <td>{item.country_phone_length && item.country_phone_length}</td>
                                            <td className="td-action">
                                                <Link to={`${urlCountries}/update/${item.id_country}`} className='td-link edit me-2'>
                                                    <MdEdit className='icon' />
                                                </Link>
                                                <Link to='' className='td-link delete ms-2' onClick={() => deleteData(item.id_country, item.country_name)}>
                                                    <MdDelete className='icon' />
                                                </Link>
                                            </td>
                                        </tr>
                                    </Fragment>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </section>

            <ModelTemplapleSimple show={showModalDelete} hide={() => { setShowModalDelete(false) }} children={
                <>
                    <div className='grid-custom-one-column grid-gap-custom-normal'>
                        <div className='content-body'>
                            <p>Souhaitez-vous supprimer <strong>{showModalDeleteData.name}</strong> ?</p>
                        </div>
                        <KCustomButtonDouble
                            loading={loading}
                            textel={`Non`}
                            texter={`Oui`}
                            clickl={() => { setShowModalDelete(false) }}
                            clickr={() => deleteCountries(Number(showModalDeleteData.id))}
                            typel={`button`}
                            typer={`button`} />
                    </div>
                </>
            } className={'content'} modalClassName={'modal-delete-date-table'} />

            <ToastMessageTopRight />
        </>
    );
};
