import { Route, Routes } from "react-router-dom";
import { Orders } from "../views/Orders";


export const ControllersOrders: React.FC = () => {
    return (
        <>
            <Routes>
                <Route path='' element={<Orders />} />
            </Routes>
        </>
    );
};