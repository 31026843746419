import axios from 'axios';
import { auhtenticatedResquest } from './ServicesSessions';

const baseURL = 'https://webapptest.diasporacare.net/api/'; 
export const urlMedias = 'https://webapptest.diasporacare.net/api/media/'; 

export const BASE_URL = axios.create({
  baseURL,
  // withCredentials: false,
});



export const headerRequest : any = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
}

export const headerRequestAuth : any = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'token': `${auhtenticatedResquest.token}`,
  'username': `${auhtenticatedResquest.username}`, 
}

// const data = {
//   token: auhtenticatedResquest.token,
//   username: auhtenticatedResquest.username
// };