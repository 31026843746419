import React, { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ServiceSessions } from '../services/ServicesSessions';

const INACTIVITY_LIMIT = 360 * 60 * 1000; // 360 minutes en millisecondes

export const isAuthenticated = (): boolean => {
    const token = ServiceSessions.getToken();    
    return !!token && token.trim() !== '';
};

interface AuthGaurdProps {
    children: ReactNode;
}

export const AuthGaurd: React.FC<AuthGaurdProps> = ({ children }) => {

    const navigate = useNavigate();
    const session = ServiceSessions.getSession();

    useEffect(() => {

        if(!isAuthenticated()){
            navigate('/login');
            ServiceSessions.logout();
        } 

        const checkSession = () => {
            if (session && typeof session === 'number') {
                const now = Date.now();
                const timeElapsed = now - session;

                if (timeElapsed > INACTIVITY_LIMIT) {
                    ServiceSessions.logout();
                    navigate('/login');
                }
            }
        };

        const resetInactivity = () => { 
            if (session && typeof session === 'number') {
                localStorage.setItem('userSession', JSON.stringify(Date.now()));
            }
        };

        const handleUserActivity = () => {
            resetInactivity();
        }; 
        
        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
        window.addEventListener('click', handleUserActivity);

        // Vérifie toutes les 10 secondes
        const interval = setInterval(checkSession, 10000);

        return () => {
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('keydown', handleUserActivity);
            window.removeEventListener('click', handleUserActivity);
            clearInterval(interval);
        };
    }, [navigate, session]); 
    
    return <>{children}</>;
};
