import React, { Fragment, useEffect, useState } from 'react';
import { ServicesLocalities } from '../../../../../../services/api/ServicesLocalities';
import { InterfaceCountriesGet, InterfaceLocalitiesCreate, urlLocalities } from '../../../models/ModelsSettings';
import { Link, useNavigate } from 'react-router-dom';
import { auhtenticatedResquest } from '../../../../../../services/ServicesSessions';
import { KCustomButtonDouble } from '../../../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';
import { ServicesCountries } from '../../../../../../services/api/ServicesCountries';

export const CreateLocalities = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [localities, setLocalities] = useState<InterfaceLocalitiesCreate>({
        locality_name: "",
        locality_status: null,
        id_country: null,
        username: auhtenticatedResquest.username,
        token: auhtenticatedResquest.token,
    });

    const [countries, setCountries] = useState<InterfaceCountriesGet[]>([]);


    const handleOnChange = (e: any) => {
        setLocalities({
            ...localities,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        document.title = "Localites";
    });

    useEffect(() => {
        getCountries();
    }, []);

    // Get Countries
    const getCountries = async () => {
        await ServicesCountries.read().then((res) => {
            setCountries(res.data.data);
        }).catch((err) => {
            return err
        });
    };

    const createLocalities = async (e: any) => {
        e.preventDefault();

        setLoading(true);

        const data: InterfaceLocalitiesCreate = {
            locality_name: localities.locality_name,
            locality_status: localities.locality_status === "true" ? true : false,
            id_country: Number(localities.id_country),
            username: auhtenticatedResquest.username,
            token: auhtenticatedResquest.token,
        };

        await ServicesLocalities.create(data).then((res) => {
            navigate(urlLocalities);
            // success message
        }).catch((err: any) => {
            // error message
        });

        setLoading(false);
    };


    return (
        <>
            <section id='admin-settings-geography-countries-create'>
                <div className='cusom-go-back-page'>
                    <Link to={`${urlLocalities}`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Add  country</h4>
                    </div>
                </div>
                <form onSubmit={(e) => createLocalities(e)} className='custom-form'>
                    <div className="mb-2">
                        <label className="form-label"> Name</label>
                        <input onChange={handleOnChange} name='locality_name' type="text" className="form-control" />
                        <small className={`${localities.locality_name === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-2">
                        <label className="form-label">Status  </label>
                        <select className={`form-select`} onChange={handleOnChange} name='locality_status'>
                            <option>Select</option>
                            <option value={'true'}>True</option>
                            <option value={'false'}>False</option>
                        </select>
                        <small className={`${localities.locality_status === null || localities.locality_status === "Select" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-2">
                        <label className="form-label">Pays  </label>
                        <select className={`form-select`} onChange={handleOnChange} name='id_country'>
                            <option>Select</option>
                            {
                                countries.map((item, index) => (
                                    <Fragment key={index}>
                                        <option value={item.id_country}>{item.country_name}</option>
                                    </Fragment>
                                ))
                            }
                        </select>
                        <small className={`${localities.id_country === null || localities.locality_status === "Select" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate(urlLocalities) }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};
