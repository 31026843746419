import React, { useEffect, useState } from 'react';
import { ServicesCountries } from '../../../../../../services/api/ServicesCountries';
import { InterfaceCountriesUpdate, urlCountries } from '../../../models/ModelsSettings';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { auhtenticatedResquest } from '../../../../../../services/ServicesSessions';
import { readFileAsBase64 } from '../../../../GlobalInterface';
import { IoChevronBackSharp } from 'react-icons/io5';
import { KCustomButtonDouble } from '../../../../../../components/Button';


export const UpdateCountries: React.FC = () => {

    const navigate = useNavigate();
    const { id_countries } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [countries, setCountries] = useState<InterfaceCountriesUpdate>({
        country_name: "",
        country_prefixe: "",
        id_country: Number(id_countries),
        country_phone_length: null,
        country_code: "",
        username: auhtenticatedResquest.username,
        token: auhtenticatedResquest.token,
        country_flag_base64: "",
    });


    const handleOnChange = (e: any) => {
        setCountries({
            ...countries,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        document.title = "Countries";
    });

    useEffect(() => {
        getCountriesById();
    }, []);

    // Get Countries
    const getCountriesById = async () => {
        await ServicesCountries.read().then((res) => {
            setCountries(res.data.data);
        }).catch((err) => {
            return err
        });
    };


    //---------------------------------------------------------------------------------- 
    //------------------ Transform input file for formulary in base 64 -----------------
    //----------------------------------------------------------------------------------

    const handleFileOnChange = async (e: any) => {

        const files = e.target.files;
        if (!files) return;

        convertInputFileToBase64(files);

    };

    const convertInputFileToBase64 = async (files: any) => {

        for (let i = 0; i < files.length; i++) {
            const fileType = files[i].type.split('/')[0];
            const isImageOrVideo = fileType === 'image' || fileType === 'video';

            if (isImageOrVideo) {
                try {
                    const base64Data = await readFileAsBase64(files[i]);
                    let onlyBase64 = base64Data.split(',')[base64Data.split(',').length - 1];
                    setCountries({ ...countries, country_flag_base64: onlyBase64 });
                } catch (error) {
                    console.error('Error reading file:', error);
                }
            } else {
                console.error(`Unsupported file type: ${fileType}`);
            }
        }
    }

    const updateCountries = async (e: any) => {
        e.preventDefault();

        setLoading(true);

        const data: InterfaceCountriesUpdate = countries;

        await ServicesCountries.create(data).then((res) => {
            navigate(urlCountries);
            // success message
        }).catch((err: any) => {
            // error message
        });

        setLoading(false);
    };


    return (
        <>
            <section id='admin-settings-geography-countries-create'>
                <div className='cusom-go-back-page'>
                    <Link to={`${urlCountries}`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Update  country</h4>
                    </div>
                </div>
                <form onSubmit={(e) => updateCountries(e)} className='custom-form'>
                    <div className="mb-3">
                        <label className="form-label"> Name</label>
                        <input onChange={handleOnChange} name='country_name' type="text" className="form-control" />
                        <small className={`${countries.country_name === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label className="form-label"> Prefix country </label>
                        <input onChange={handleOnChange} name='country_prefixe' type="text" className="form-control" />
                        <small className={`${countries.country_prefixe === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label className="form-label"> Flag </label>
                        <input onChange={handleFileOnChange} name='country_flag_base64' type="file" className="form-control" />
                        <small className={`${countries.country_flag_base64 === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate("/admin/clients") }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};
