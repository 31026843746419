import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { KCustomButtonDouble, KCustomButtonSimpleOuline } from '../../../../components/Button';
import { InterfaceDeleteTableRow } from '../../GlobalInterface';
import { ServicesUserStaff } from '../../../../services/api/ServicesAuths';
 import { SpinnerData } from '../../../../components/SpinnerData';
import { MdDelete, MdEdit } from 'react-icons/md';
import { InterfaceUserGet } from '../models/ModelsUsers';
import { ModelTemplapleSimple } from '../../../../components/CustomModal';
import { ToastMessageTopRight } from '../../../../components/ToastMessage';


export const Users: React.FC = () => {
    
    const [users, setUsers] = useState<InterfaceUserGet[]>([]);
    const [loadData, SetLoadData] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
    const [showModalDeleteData, setShowModalDeleteData] = useState<InterfaceDeleteTableRow>({
        name: "",
        id: ""
    }); 
    
    useEffect(() => {
        document.title = "Users";
    });

    useEffect(() => {
        getUsers();
    }, []);    
     
    // Get Users
    const getUsers = async () => { 
        await ServicesUserStaff.read().then((res) => {
            console.log("data", users);
            setUsers(res.data.data);
            SetLoadData(false);
        }).catch((err) => {
            return err
        });
    };
    
    // Delete Users
    const deleteUsers = async (id : number) => {
        setLoading(true);
        await ServicesUserStaff.delete(id).then((res : any) => {
            setShowModalDelete(false);
            getUsers();      
            // success message
        }).catch((err) => {
            // error message
        });
        setLoading(false);
    };

    const deleteData  = (id : number | string, kname : string) => {
        setShowModalDeleteData({
            name: kname,
            id: id,
        });
        setShowModalDelete(true); 
    }

    return (
        <>
            <section id='admin-users'>
                <div className='grid-custom-one-column'>
                    <h4 className='title'>user list</h4>
                    <div className='custom-btn-header custom-end'>
                        <Link to='#' className='link-btn-custom'>
                            <KCustomButtonSimpleOuline loading={false} type={`button`} texte={`Add`} />
                        </Link>
                    </div>
                </div>

                <div className='responsible-table-custom'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Country</th>
                                <th scope="col">Full name</th>
                                <th scope="col">identifier</th>
                                <th scope="col">Email</th>
                                <th scope="col">Status</th>
                                <th scope="col">Roles</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={`${users && users.length !== 0 && 'd-none'}`}>
                                <td colSpan={6} className={``}> 
                                    {loadData && (
                                        <SpinnerData />
                                    )}
                                    {
                                        !loadData && users.length === 0 && (
                                            <>No data available</>
                                        )
                                    }
                                </td>
                            </tr>
                            {
                                !loadData && users.map((item, index) =>
                                    <Fragment key={index}>
                                        <tr>
                                            <td>{item.pays && item.pays}</td>
                                            <td>{item.nom && item.nom}{item.prénoms && item.prénoms}</td>
                                            <td>{item.username && item.username}</td>
                                            <td>{item.email && item.email }</td>
                                            <td>{item.account_status && item.account_status }</td>
                                            <td>{item.roles && item.roles}</td>
                                            <td className="td-action">
                                                <Link to={`#`} className='td-link edit me-2'>
                                                    <MdEdit className='icon' />
                                                </Link>
                                                <Link to='' className='td-link delete ms-2' onClick={() => deleteData(item.nom, `${item.nom} ${item.prénoms}`)}>
                                                    <MdDelete className='icon' />
                                                 </Link>
                                            </td>
                                        </tr> 
                                    </Fragment>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </section>
             
            <ModelTemplapleSimple show={showModalDelete} hide={() => { setShowModalDelete(false) }} children={
                <>
                    <div className='grid-custom-one-column grid-gap-custom-normal'>
                        <div className='content-body'>
                            <p>Souhaitez-vous supprimer <strong>{showModalDeleteData.name}</strong> ?</p>
                        </div>
                        <KCustomButtonDouble
                            loading={loading}
                            textel={`Non`}
                            texter={`Oui`}
                            clickl={() => { setShowModalDelete(false) }}
                            clickr={() => deleteUsers(Number(showModalDeleteData.id))}
                            typel={`button`}
                            typer={`button`} />
                    </div>
                </>
            } className={'content'} modalClassName={'modal-delete-date-table'} />
            <ToastMessageTopRight />
        </>
    );
};
