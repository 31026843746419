
export interface InterfaceMenuItemWithoutIcon {
    name: string;
    path: string;
    sub_navbar: string | null;
    location_path: string;
}

export interface InterfaceDeleteTableRow {
    name: string;
    id: number | string;
}

export interface InterfaceSecurityRequestRead  {
    token: string | null ;
    username: string | null;
}

export interface InterfaceMediaFile {
    media_file: string;
}

export interface InterfaceMediaState {
    medias: InterfaceMediaFile[];
}

export const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            if (reader.result) {
                resolve(reader.result as string);
            } else {
                reject(new Error('Failed to read file'));
            }
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsDataURL(file);
    });
};