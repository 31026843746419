import React, { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { KCustomButtonSimple } from '../../../components/Button';
import { ToastMessageTopCenter } from '../../../components/ToastMessage';
import logo from "../../../assets/images/logo/Logo_icon.png";
import { ServicesUserStaff } from '../../../services/api/ServicesAuths';
import { IoIosEyeOff, IoMdEye } from "react-icons/io";
import { ServiceSessions } from '../../../services/ServicesSessions';
import { InterfaceUserStaffLogin } from '../models/ModelsUsers';
import { isAuthenticated } from '../../../helpers/AuthGaurd';


interface LoginState {
    username: string;
    password: string;
}

export const Login: React.FC = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [showHiddenPassword, setShowHiddenPassword] = useState<Boolean>(false);
    const navigate = useNavigate();
    const location = useLocation().pathname;

    const [login, setLogin] = useState<LoginState>({
        username: "",
        password: "",
    });

    useEffect(() => {
        document.title = "Login";
        if (isAuthenticated() && (location === "/" || location === "/login")) {
            navigate('/admin/dashboard');
        }

    }, [location, navigate]);

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        setLogin({
            ...login,
            [e.target.name]: e.target.value
        });
    }

    const postLogin = async (e: any) => {
        e.preventDefault();

        setLoading(true);

        const data: InterfaceUserStaffLogin = login;

        await ServicesUserStaff.login(data).then((res) => {
            if (res.status === 200) {
                setLoading(true);      
            // success message
                const { token } = res.data;
                const {
                    username,
                    nom,
                    email,
                    pays,
                    paysPrefixe,
                    paysUrl,
                    roles,
                    roles_status,
                    channel,
                    account_status,
                    roles_privileges
                } = res.data.data;
                ServiceSessions.saveAuth({
                    username,
                    nom,
                    email,
                    pays,
                    paysPrefixe,
                    token,
                    paysUrl,
                    roles,
                    roles_status,
                    channel,
                    account_status,
                    roles_privileges
                });

                ServiceSessions.saveSession();

                navigate('/admin/dashboard');
            }
        }).catch((err) => {
            // error message
        });

        setLoading(false);
    };


    return (
        <>
            <section id='login'>
                <div className='content'>
                    <img src={logo} width={100} height={100} alt='field360' />
                    <form onSubmit={(e) => postLogin(e)} className='grid-custom-one-column grid-gap-custom-normal'>
                        <div className="">
                            <label htmlFor="" className="form-label"> Nom d'utilisateur </label>
                            <input type="text" className="form-control" onChange={handleOnChange} name='username' placeholder="" />
                            <small className={`${login.username === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                        </div>
                        <div className="password-field-custom">
                            <label htmlFor="" className="form-label"> Mot de passe </label>
                            <input type={showHiddenPassword ? `text` : `password`} onChange={handleOnChange} name='password' className="form-control" placeholder="" />
                            <span className="icon-disploy-hidden" onClick={() => setShowHiddenPassword(!showHiddenPassword)}>
                                {
                                    showHiddenPassword ? (
                                        <> <IoIosEyeOff className='icon' /> </>
                                    ) : (
                                        <> <IoMdEye className='icon' /> </>
                                    )
                                }
                            </span>
                            <small className={`${login.password === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                        </div>
                        <span className='d-block mt-2' />
                        <KCustomButtonSimple loading={loading} texte={`Connexion`} type={`submit`} />
                    </form>
                </div>
            </section>
            <div className="copyright">
                <p>Power By Gaussoft</p>
            </div>
            <ToastMessageTopCenter />
        </>
    );
};