import { InterfaceSecurityRequestRead } from "../apps/admins/GlobalInterface";

interface Privilege {
    id_privilege: number;
    privileges_name: string;
    privilege_code: string;
}

interface Privilege {
    id_privilege: number;
    privileges_name: string;
    privilege_code: string;
}

interface UserData {
    username: string;
    nom: string;
    email: string;
    pays: string;
    paysPrefixe: string;
    token: string;
    paysUrl: string;
    roles: string;
    roles_status: string;
    channel: string;
    account_status: string;
    roles_privileges: Privilege[] | null;
}


export class ServiceSessions {

    static saveAuth = (userData: UserData): void => {

        const { username, nom, email, pays, paysPrefixe, token, paysUrl, roles, roles_status, channel, account_status, roles_privileges } = userData;

        localStorage.setItem('username', username);
        localStorage.setItem('name', nom);
        localStorage.setItem('email', email);
        localStorage.setItem('pays', pays);
        localStorage.setItem('paysPrefixe', paysPrefixe);
        localStorage.setItem('token', token);
        localStorage.setItem('paysUrl', paysUrl);
        localStorage.setItem('roles', roles);
        localStorage.setItem('roles_status', roles_status);
        localStorage.setItem('channel', channel);
        localStorage.setItem('account_status', account_status);
        localStorage.setItem('roles_privileges', JSON.stringify(roles_privileges));
    }

    static getUsername = (): string | null => {
        return localStorage.getItem('username');
    }

    static getToken = (): string | null => {
        return localStorage.getItem('token');
    }

    static getRolesPrivileges = (): any => {
        return localStorage.getItem('roles_privileges');
    }

    static logout = (): void => {
        localStorage.removeItem('username');
        localStorage.removeItem('name');
        localStorage.removeItem('email');
        localStorage.removeItem('pays');
        localStorage.removeItem('paysPrefixe');
        localStorage.removeItem('token');
        localStorage.removeItem('paysUrl');
        localStorage.removeItem('roles');
        localStorage.removeItem('roles_status');
        localStorage.removeItem('channel');
        localStorage.removeItem('account_status');
        localStorage.removeItem('roles_privileges'); 
        localStorage.removeItem('userSession'); 
    }

    static saveSession = () => {
        const session: number = Date.now();
        localStorage.setItem("userSession",  JSON.stringify(session));
    };
    
    static getSession = (): number => {
        const session = localStorage.getItem("userSession");
        return session ? JSON.parse(session) : null;
    };
     
}



export const auhtenticatedResquest: InterfaceSecurityRequestRead = {
    token: ServiceSessions.getToken(),
    username: ServiceSessions.getUsername()
}



